@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

:root {
  --primary-color: #fff;
  --tier1: rgba(255, 73, 33, 1);
  --tier2: rgba(115, 0, 255, 1);
  --tier3: rgba(204, 101, 225, 1);
  --tier4: rgba(187, 19, 167, 1);
  --tier5: rgba(162, 69, 254, 1);
  --tier6: rgba(255, 184, 178, 1);
  --tier7: rgba(254, 119, 119, 1);
  --tier8: rgba(194, 133, 255, 1);
  --default-cubic: cubic-bezier(0.25, 0.625, 0.01, 0.98);
}

html {
  color: rgba(245, 245, 245, 1);
  * {
    scrollbar-width: none;
    user-select: none;
  }

  *::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  // Calendar Styles
  .rdp-root {
    --rdp-accent-color: rgba(
      92,
      80,
      148,
      1
    ); /* Change the accent color to indigo. */
    --rdp-accent-background-color: #f0f0f0; /* Change the accent background color. */
    color: rgba(25, 22, 44, 1);
    display: flex;
    justify-content: center;
    padding: 1rem;

    .rdp-selected {
      background-color: rgba(25, 22, 44, 1);
      border-radius: 100%;
      color: rgba(245, 245, 245, 1) !important;
      font-size: medium;

      button {
        border: none;
      }
    }

    .rdp-today {
      opacity: 1;
    }
  }

  .rdp-week > td {
    pointer-events: none;
    color: gray;
    opacity: 0.4;
  }

  .highlighted {
    pointer-events: auto !important;
    color: rgba(25, 22, 44, 1) !important;
    opacity: 1 !important;

    /* Adds a small dot below highlighted dates */
    &::after {
      content: '';
      position: absolute;
      bottom: 4px; /* Adjust the dot's position */
      left: 50%;
      transform: translateX(-50%);
      width: 5px; /* Dot size */
      height: 5px;
      background-color: rgba(25, 22, 44, 1);
      border-radius: 50%;
    }
  }
}
